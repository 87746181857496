// extracted by mini-css-extract-plugin
export var background = "soon-module--background--31972";
export var banner = "soon-module--banner--e954e";
export var banner_icon = "soon-module--banner_icon--1bcfc";
export var bottom_row = "soon-module--bottom_row--dc236";
export var content_container = "soon-module--content_container--29f98";
export var icon_links = "soon-module--icon_links--8423c";
export var info = "soon-module--info--36f11";
export var left_text = "soon-module--left_text--6d641";
export var link = "soon-module--link--a5b82";
export var logo = "soon-module--logo--6d97a";
export var rotating = "soon-module--rotating--71372";
export var text_info = "soon-module--text_info--dfa01";
export var title = "soon-module--title--536ad";
export var top_row = "soon-module--top_row--1e488";